import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import DashCard from "./DashCard";
const dash1 = [
  {
    id: 1,
    icon: "../../assets/images/tabs.png",
    title: "Chat with Quidx Ai",
    body: "Get 100% Personlized Mentorship in Forex and Web 3.0",
    link: "/chat",
  },
  {
    id: 2,
    icon: "../../assets/images/tabs.png",
    title: "Risk Manager",
    body: "Use proper risk management by calculating your risk with just a few clicks.",
    link: "/calculator",
  },
  // {
  //   id: 3,
  //   icon: "../../assets/images/tab2.png",
  //   title: "Journal X",
  //   body: "Journal your trades and get AI driven insights on improving your trading style",
  //   link: "/journal",
  //   isJournal: true,
  // },
];
const dash2 = [
  {
    id: 1,
    icon: "../../assets/images/tabs.png",
    title: "Trading Insights",
    body: "Get accurate data driven insights of the Forex and Crypto markets",
  },
  {
    id: 2,
    icon: "../../assets/images/tab2.png",
    title: "Blog Post",
    body: "Be the first to know about the latest happenings in the AI, Forex and Web 3 space.",
    link: "https://medium.com/@quidxai_18046/introducing-quidx-ai-revolutionizing-financial-trading-and-web-3-0-for-all-e730a1fa6cd3",
  },
  {
    id: 3,
    icon: "../../assets/images/tradSign.png",
    title: "Trading Signals",
    body: "Join our AI backed community to get 100% accurate trading signals.",
    link: "https://chat.whatsapp.com/KWwLVLOfUjLDH7c0dcGA3k",
  },
];
export const DashCarouselOne = () => {
  const [start, setStart] = useState(0);

  const variants = {
    initial: { opacity: 0, x: -30 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 30 },
  };
  const divsOnDisplay = dash1.slice(start, start + 3);
  const handlePrev = () => {
    if (start > 0) {
      setStart(start - 1);
    }
  };
  const handleNext = () => {
    if (start + 3 < dash1.length) {
      setStart(start + 1);
    }
  };
  return (
    <>
      <HStack
        width={"full"}
        gap={["25px", "25px", "25px"]}
        py={"12px"}
        overflowX={"scroll"}
        display={["none", "flex"]}
      >
        <AnimatePresence>
          {divsOnDisplay.map((item) => (
            <motion.div
              key={item.id}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <DashCard
                key={item.id}
                title={item.title}
                icon={item.icon}
                body={item.body}
                link={item.link}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </HStack>
      <HStack
        width={"full"}
        justifyContent={"space-between"}
        display={["none", "flex"]}
      >
        <Box
          p={"12px"}
          bg={"#DAF1DB"}
          borderRadius={"8px"}
          _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
          onClick={handlePrev}
        >
          <MdArrowBack size={"20px"} />
        </Box>
        <Box
          p={"12px"}
          bg={"#3EA842"}
          color={"#fff"}
          borderRadius={"8px"}
          _hover={{ p: "13px" }}
          onClick={handleNext}
        >
          <MdArrowForward size={"20px"} />
        </Box>
      </HStack>
    </>
  );
};

export const MobileCarousel1 = () => {
  const [start, setStart] = useState(0);

  // const variants = {
  //   initial: { opacity: 0, x: -10 },
  //   animate: { opacity: 1, x: 0 },
  //   exit: { opacity: 0, x: 10 },
  // };
  const handleNext = () => {
    // if (start < testimonials.length - 1) {
    //   setStart(start + 1);}
    setStart(start === dash1.length - 1 ? 0 : start + 1);
  };

  const handlePrev = () => {
    // if (start > 0) {
    //   setStart(start - 1);
    // }
    setStart(start === 0 ? dash1.length - 1 : start - 1);
  };

  return (
    <VStack display={["block", "none"]} width={"100%"}>
      <HStack width={"full"} gap={["25px", "25px", "25px"]}>
        <AnimatePresence initial={false} custom={start}>
          {dash1.map(
            (item, i) =>
              i === start && (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <DashCard
                    key={item.id}
                    title={item.title}
                    icon={item.icon}
                    name={item.name}
                    body={item.body}
                  />
                </motion.div>
              )
          )}
        </AnimatePresence>
      </HStack>
      <br />
      <HStack width={"full"} justifyContent={"space-between"}>
        <Box
          p={"12px"}
          bg={"#DAF1DB"}
          borderRadius={"8px"}
          _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
          onClick={handlePrev}
        >
          <MdArrowBack size={"20px"} />
        </Box>
        <Box
          p={"12px"}
          bg={"#3EA842"}
          color={"#fff"}
          borderRadius={"8px"}
          _hover={{ p: "13px" }}
          onClick={handleNext}
        >
          <MdArrowForward size={"20px"} />
        </Box>
      </HStack>
    </VStack>
  );
};

export const DashCarouselTwo = () => {
  const [start, setStart] = useState(0);

  const variants = {
    initial: { opacity: 0, x: -30 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 30 },
  };
  const divsOnDisplay = dash2.slice(start, start + 3);
  const handlePrev = () => {
    if (start > 0) {
      setStart(start - 1);
    }
  };
  const handleNext = () => {
    if (start + 3 < dash2.length) {
      setStart(start + 1);
    }
  };
  return (
    <>
      <HStack
        width={"full"}
        gap={["25px", "25px", "25px"]}
        display={["none", "flex"]}
      >
        <AnimatePresence>
          {divsOnDisplay.map((item) => (
            <motion.div
              key={item.id}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <DashCard
                key={item.id}
                title={item.title}
                icon={item.icon}
                body={item.body}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </HStack>
      <HStack
        width={"full"}
        justifyContent={"space-between"}
        display={["none", "flex"]}
      >
        <Box
          p={"12px"}
          bg={"#DAF1DB"}
          borderRadius={"8px"}
          _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
          onClick={handlePrev}
        >
          <MdArrowBack size={"20px"} />
        </Box>
        <Box
          p={"12px"}
          bg={"#3EA842"}
          color={"#fff"}
          borderRadius={"8px"}
          _hover={{ p: "13px" }}
          onClick={handleNext}
        >
          <MdArrowForward size={"20px"} />
        </Box>
      </HStack>
    </>
  );
};
export const MobileCarousel2 = () => {
  const [start, setStart] = useState(0);

  // const variants = {
  //   initial: { opacity: 0, x: -10 },
  //   animate: { opacity: 1, x: 0 },
  //   exit: { opacity: 0, x: 10 },
  // };
  const handleNext = () => {
    // if (start < testimonials.length - 1) {
    //   setStart(start + 1);}
    setStart(start === dash2.length - 1 ? 0 : start + 1);
  };

  const handlePrev = () => {
    // if (start > 0) {
    //   setStart(start - 1);
    // }
    setStart(start === 0 ? dash2.length - 1 : start - 1);
  };

  return (
    <VStack display={["block", "none"]} width={"100%"}>
      <HStack width={"full"} gap={["25px", "25px", "25px"]}>
        <AnimatePresence initial={false} custom={start}>
          {dash2.map(
            (item, i) =>
              i === start && (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <DashCard
                    key={item.id}
                    title={item.title}
                    icon={item.icon}
                    name={item.name}
                    body={item.body}
                  />
                </motion.div>
              )
          )}
        </AnimatePresence>
      </HStack>
      <br />
      <HStack width={"full"} justifyContent={"space-between"}>
        <Box
          p={"12px"}
          bg={"#DAF1DB"}
          borderRadius={"8px"}
          _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
          onClick={handlePrev}
        >
          <MdArrowBack size={"20px"} />
        </Box>
        <Box
          p={"12px"}
          bg={"#3EA842"}
          color={"#fff"}
          borderRadius={"8px"}
          _hover={{ p: "13px" }}
          onClick={handleNext}
        >
          <MdArrowForward size={"20px"} />
        </Box>
      </HStack>
    </VStack>
  );
};
