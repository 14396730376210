import React from 'react'
import { Link } from 'react-router-dom'

const SuccessPage = () => {

    return (
        <>
            <div className="flex bg-[var(--bg-color)] h-screen w-full items-center justify-center">
                <div className='lg:w-5/12 w-full text-center p-6 mt-5 shadow-lg rounded-md mt-4'>
                    <h1 className='text-green-700 font-[600] text-[20px]'>Congartulations</h1>
                    <h1 className='text-green-700'>Your payment was successful</h1>
                    <Link to={"/chat"} className='block bg-[var(--primary-color)] rounded-md px-5 py-3 shadow-lg text-[16] text-white font-[600] mt-5'>Continue to QuidX</Link>
                </div>
            </div>
        </>
    )
}

export default SuccessPage