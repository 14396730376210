import {
  Box,
  Button,
  GridItem,
  Image,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";

import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

const ProvideCard = (props) => {
  const toast = useToast();

  return (
    <VStack
      justifyContent={"center"}
      alignItems={"center"}
      bgColor={props.bgColor || "#D3DCD1"}
      gap={"30px"}
      textAlign={"center"}
      borderRadius={"36px"}
      boxShadow={"md"}
      py={"60px"}
      width={["100%", "300px", "400px"]}
      height={"500px"}
      px={"30px"}
      className="slide-in-bottom"
    >
      <Box
        width={["90px"]}
        height={["90px"]}
        border={"3px solid white"}
        borderRadius={"8px"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        bgColor={"#1B4B1D;"}
      >
        <Image src={props.icon} />
      </Box>
      <Text
        color={"#1B4B1D"}
        fontSize={["16px", "20px"]}
        fontWeight={["600"]}
      >
        {props.title}
      </Text>
      <Text
        color={"#333"}
        fontSize={["12px", "15px"]}
        fontWeight={["500"]}
      >
        {props.body}
      </Text>
      <Link to={"/signin"}>
        <Button
          backgroundColor={"#3EA842"}
          color={"#fff"}
          variant="ghost"
          borderRadius={"6px"}
          fontSize={["12px", "14px", "16px"]}
          _hover={{ bg: "#083205", color: "#fff" }}
          width={"fit-content"}
          onClick={() =>
            props.title === "JournalX"
              ? toast({
                  title: "Coming soon ",
                  description:
                    "The Journal is expected to be available soon. Please check back later.",
                  status: "warning",
                  duration: 5000,
                  position: "top-right",
                  isClosable: true,
                })
              : ""
          }
        >
          Learn More{" "}
        </Button>
      </Link>
    </VStack>
  );
};

export default ProvideCard;
