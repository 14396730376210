import { Box, Button, Image, Link, Text, VStack } from "@chakra-ui/react";
import React from "react";

const DashCard = (props) => {
  const link = "https://chat.whatsapp.com/KWwLVLOfUjLDH7c0dcGA3k"
  // const goToWhatsApp = ()=>{

  // }
  return (
    <VStack
      justifyContent={"center"}
      alignItems={"flex-start"}
      bgColor={props.bgColor || "#D3DCD1"}
      gap={"20px"}
      boxShadow={"md"}
      pb={"10px"}
      width={["fit-content", "fit-content"]}
    >
      <Box
        width={"full"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        bgColor={"#3EA842"}
      >
        <Image src={props.icon} />
      </Box>
      <Box
        display={"flex"}
        flexDir={"column"}
        gap={"20px"}
        px={"30px"}
        mb={"20px"}
        pb={"20px"}
      >
        <Text
          color={"#1B4B1D"}
          fontSize={["18px", "20px"]}
          fontWeight={["600"]}
        >
          {props.title}
        </Text>
        <Text color={"#333"} fontSize={["13px", "15px"]} fontWeight={["500"]}>
          {props.body}
        </Text>
        <Link href={props.link}>
          <Button
            onClick={()=> window.open(link, "_blank")}
            backgroundColor={"#3EA842"}
            color={"#fff"}
            variant="ghost"
            borderRadius={"6px"}
            fontSize={["12px", "14px", "16px"]}
            _hover={{ bg: "#083205", color: "#fff" }}
            width={"fit-content"}
          >
            Proceed{" "}
          </Button>
        </Link>
      </Box>
    </VStack>
  );
};

export default DashCard;
