import React, { useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import "../App.css";
import ThemeLayout from "../components/ThemeLayout";
import Header from "../components/Header";
import SectionContainer from "../components/SectionContainer";
import CTAButton from "../components/CTAButton";
import ResultCard from "../components/ResultCard";
import {
  MdArrowBack,
  MdArrowForward,
  MdOutlineLibraryBooks,
} from "react-icons/md";
import { TbTools } from "react-icons/tb";
import { IoMdOptions } from "react-icons/io";
import { BsPeople } from "react-icons/bs";
import { Carousel, MobileCarousel } from "../components/Carousel";
import { Link } from "react-router-dom";
import { useState } from "react";
import ProductsDiv from "../components/ProductsDiv";
import {
  TestimonialCarousel,
  MobileTestCarousel,
} from "../components/TestimonialCarousel";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import axios from "axios";

const Home = () => {
  const [activeCard, setActiveCard] = useState("RiskCalculator");
  const [email, setEmail] = useState("");
  const toast = useToast();
  const handleLeftButtonClick = () => {
    setActiveCard("RiskCalculator");
  };

  const handleRightButtonClick = () => {
    setActiveCard("JournalTool");
  };
  const [typewriterEffect] = useTypewriter({
    words: [
      // "The Perfect AI Mentor for new and Professional Forex & Crypto Traders!",
      "The perfect AI mentor for your Forex and Crypto Trading Journey",
    ],
    loop: {},
    typeSpeed: 20,
    deleteSpeed: 80,
    delaySpeed: 2000,
  });
  const sendWaitlist = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/guests`, {
        email: email,
        message: "",
      })
      .then((response) => {
        toast({
          title: "Success!",
          description: "Successfully joined waitlist",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setEmail("");
      });
  };

  useEffect(() => {
    const elements = document.querySelectorAll(
      ".fade-in, .slide-in-left, .slide-in-right, .slide-in-top, .slide-in-bottom"
    );

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.remove("hidden");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    elements.forEach((element) => {
      element.classList.add("hidden");
      observer.observe(element);
    });
  }, []);

  return (
    <ThemeLayout>
      <SectionContainer hero={true} py={"20px"}>
        <Header showLogo={true} />

        <VStack
          background="transparent"
          width={"full"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={["10px", "10px", "30px", "30px", "40px"]}
          paddingY={["40px", "50px", "70px"]}
          pb={["80px", "0px"]}
          mt={"3vh"}
          height={["auto", "auto", "fit-content"]}
        >
          <Box
            width={"full"}
            display={"flex"}
            justifyContent={"center"}
            height={["100px", "200px"]}
          >
            <Text
              color={"#141414"}
              fontSize={["22px", "22px", "38px", "38px", "54px"]}
              lineHeight={["30px", "38px", "54px", "54px", "68px"]}
              fontWeight={"700"}
              width={["100%", "100%", "80%", "80%", "60%"]}
              textAlign={"center"}
            >
              {typewriterEffect}
              <Cursor />{" "}
            </Text>
          </Box>
          <p className="text-[18px] font-[500] text-center px-0 lg:px-52">Discover a new era of simplicity and profitability in the world of forex and cryptocurrency trading with Quidstation. </p>
          {/* <Text
            color={"#333333"}
            fontSize={["12px", "14px", "18px"]}
            lineHeight={["15px", "20px", "29px"]}
            width={["100%", "100%", "70%"]}
            textAlign={"center"}
            className="fade-in"
          >
            Discover a new era of simplicity and profitablity in the Forex and
            Web 3 Space with Quidx AI{" "}
          </Text> */}
          <Link to={"/signup"}  className="bg-[var(--primary-color)] text-white text-[18px] font-[700] px-6 py-2 rounded-md">Get Started</Link>
          {/* <Link to={"/signup"}>
            {" "}
            <CTAButton className="px-10 py-10" text={"Get Started"} />
          </Link> */}
        </VStack>
      </SectionContainer>
      <Box
        width={"full"}
        bgImage={
          "linear-gradient(360deg, #F8FCF8 31.76%, rgba(218, 241, 219, 0.57) 64.07%, rgba(218, 241, 219, 0.58) 100%)"
        }
        borderColor={"rgba(218, 241, 219, 0.88)"}
        fill={
          "linear-gradient(180deg, rgba(218, 241, 219, 0.00) 31.76%, rgba(218, 241, 219, 0.37) 64.07%, rgba(218, 241, 219, 0.00) 100%)"
        }
        backgroundSize={"cover"}
        height={"45px"}
        p={0}
        bgColor={"#fff"}
        marginTop={"-20px"}
      ></Box>
      <Box
        display={"flex"}
        px={["20px", "80px"]}
        flexDir={("column", "row")}
        height={["auto", "50vh"]}
        gap={"10px"}
        alignItems="center"
        justifyContent="center"
        paddingBottom={["40px", "220px"]}
        paddingTop={["40px", "180px"]}
      >
        <Box
          width={["full", "100%"]}
          display={"flex"}
          flexDir={"column"}
          justifyContent={["center", "flex-start"]}
          alignItems={["center", "start"]}
          gap={"28px"}
          textAlign={["center", "left"]}
          className="slide-in-left"
        >
          <Text
            color={"#333333"}
            fontSize={["20px", "24px", "28px"]}
            lineHeight={["15px", "20px", "29px"]}
            // width={["100%", "100%", "fit-content"]}
            fontWeight={"600"}
          >
            About QuidX AI{" "}
          </Text>
          <Text
            color={"#333333"}
            fontSize={["14px", "14px", "18px"]}
            lineHeight={["18px", "22px", "31px"]}
            width={["100%", "70%", "70%"]}
          >
            Creating a future where the Forex and Web 3 space is accesible and
            profitbalable for all, irrespective of your background or
            experience.
          </Text>
          {/* <Link to={"/signin"}>
            <Button
              className="lg:block hidden"
              backgroundColor={"#3EA842"}
              color={"#fff"}
              variant="ghost"
              borderRadius={"6px"}
              fontSize={["12px", "14px", "16px"]}
              _hover={{ bg: "#083205", color: "#fff" }}
              width={"fit-content"}
            >
              Get Started
            </Button>
          </Link> */}
        </Box>
        <Box
          display={["none", "flex"]}
          flexDir={["column"]}
          w={"90%"}
          h={"fit-content"}
          position={"relative"}
          className="slide-in-right"
        >
          <Image
            src="../../assets/about/img3.png"
            alignSelf={"flex-end"}
            zIndex={"10"}
            w="200px"
          />
          <Image
            src="../../assets/about/img1.png"
            width={"400px"}
            margin={"none"}
            alignSelf={"center"}
            position={"absolute"}
            top={"100px"}
          />
          <Image
            src="../../assets/about/img2.png"
            alignSelf={"flex-start"}
            // marginBottom={"-50px"}
            zIndex={"10"}
            w="200px"
          />
        </Box>
      </Box>
      <Box width={"full"}>
        <SectionContainer bg={"#1B4B1D"} width="100%" provide={true}>
          <HStack
            width={"full"}
            alignItems={"center"}
            bg={"#1B4B1D"}
            justifyContent={["center", "center", "space-between"]}
            gap={["0px", "10px"]}
            flexWrap={"wrap"}
            paddingY={"20px"}
          >
            <ResultCard
              text={"100k+ Prompts"}
              icon={<MdOutlineLibraryBooks />}
            />
            <ResultCard text={"10+ Trading tools"} icon={<TbTools />} />
            <ResultCard text={"20+ Trading Pairs"} icon={<IoMdOptions />} />
            <ResultCard text={"5k+ Users"} icon={<BsPeople />} />
          </HStack>
        </SectionContainer>
      </Box>
      <div id="#features" />

      <Box
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        textAlign={"center"}
        py={"40px"}
        gap={"30px"}
        px={["35px", "75px"]}
      >
        <Text
          color={"#1B4B1D"}
          fontSize={["20px", "24px", "28px"]}
          fontWeight={["600"]}
        >
          We Provide
        </Text>

        <Carousel />
        <MobileCarousel />
      </Box>
      <Box
        display={"flex"}
        flexDir={["column", "column", "row"]}
        bg={"#ECF8ED"}
        flexDirection={["column", "row"]}
        justifyContent={"space-between"}
        alignItems={"center"}
        textAlign={"center"}
        w={["full", "80%"]}
        py={"60px"}
        gap={"30px"}
        px={["20px", "75px"]}
      >
        <Box
          display={"flex"}
          flexDir={"column"}
          gap={"32px"}
          width={["full", "40%"]}
          alignItems={"left"}
          justifyContent={"left"}
          textAlign={["center", "left"]}
          className="slide-in-left"
        >
          <Text
            color={"#1B4B1D"}
            fontSize={["18px", "28px"]}
            fontWeight={["600"]}
          >
            Popular Products{" "}
          </Text>
          <Text
            color={"#333333"}
            fontSize={["13px", "16px"]}
            fontWeight={["500"]}
            mb={"20px"}
          >
            We understand that time is of the essence in the fast-paced trading
            environment. That's why we've developed a user-friendly platform
            that allows you to execute trades effortlessly and efficiently.
          </Text>
          <Box
            display={["none", "flex"]}
            gap={"20px"}
            width={"fit-content"}
            justifyContent={"center"}
          >
            <Box
              p={"12px"}
              bg={"#DAF1DB"}
              borderRadius={"8px"}
              _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
              onClick={handleLeftButtonClick}
            >
              <MdArrowBack size={"20px"} />
            </Box>
            <Box
              p={"12px"}
              bg={"#3EA842"}
              color={"#fff"}
              borderRadius={"8px"}
              _hover={{ p: "13px" }}
              onClick={handleRightButtonClick}
            >
              <MdArrowForward size={"20px"} />
            </Box>
          </Box>
        </Box>
        <ProductsDiv />
        <Box
          display={["none", "flex"]}
          flexDir={["row", "row"]}
          gap={["10px", "-30px"]}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
          >
            <Box
              color={activeCard === "RiskCalculator" ? "#fff" : "#1B4B1D"}
              display={"flex"}
              flexDirection={"column"}
              bg={activeCard === "RiskCalculator" ? "#3EA842" : "#D3DCD1"}
              alignItems={"center"}
              borderRadius={"36px"}
              transition={"all 0.3s ease"}
              py={["30px", "50px"]}
              justifyContent={"space-around"}
              gap={["10px", "30px"]}
              maxWidth={
                activeCard === "RiskCalculator"
                  ? ["50%", "360px"]
                  : ["100%", "260px"]
              }
              maxHeight={
                activeCard === "RiskCalculator"
                  ? ["50%", "fit-content"]
                  : ["100%", "400px"]
              }
            >
              <Box
                width={["80px"]}
                height={["80px"]}
                border={"3px solid white"}
                borderRadius={"8px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bgColor={"#F8FCF8"}
                transition={"all 0.6s ease"}
              >
                <Image src="../../assets/images/calculator.png" />
              </Box>
              <Text
                fontSize={
                  activeCard === "RiskCalculator" ? "20px" : ["12px", "17px"]
                }
                fontWeight={["600"]}
              >
                Airdrop Genius
              </Text>
              <Text
                fontSize={activeCard === "RiskCalculator" ? "15px" : ["13px"]}
                fontWeight={["500"]}
                w={["70%", "80%"]}
              >
                In 5 minuets effortlessly verify the legiticmacy of any airdrop
                using AI
              </Text>
              <Link to={"/signin"}>
                <Button
                  backgroundColor={"#fff"}
                  color={"#3EA842"}
                  variant="ghost"
                  borderRadius={"6px"}
                  fontSize={["12px", "14px", "16px"]}
                  _hover={{ bg: "#083205", color: "#fff" }}
                  width={"fit-content"}
                >
                  Learn More{" "}
                </Button>
              </Link>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              bg={activeCard === "JournalTool" ? "#3EA842" : "#D3DCD1"}
              alignItems={"center"}
              borderRadius={"36px"}
              py={"50px"}
              justifyContent={"space-around"}
              gap={"20px"}
              maxWidth={
                activeCard === "JournalTool"
                  ? ["50%", "360px"]
                  : ["100%", "260px"]
              }
              maxHeight={
                activeCard === "JournalTool"
                  ? ["50%", "fit-content"]
                  : ["100%", "400px"]
              }
              // color={activeCard === "JournalTool" ? "#eee" : "black"}
              color={"white"}
            ///
            >
              <Box
                width={["80px"]}
                height={["80px"]}
                border={"3px solid white"}
                borderRadius={"8px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bgColor={"#333"}
                transition={"all 0.6s ease"}
              >
                <Image src="../../assets/images/provide/three.png" />
              </Box>
              <Text
                color={"#1B4B1D"}
                fontSize={
                  activeCard === "JournalTool" ? "20px" : ["12px", "17px"]
                }
                fontWeight={["600"]}
              >
                Trading Insights{" "}
              </Text>
              <Text
                color={"#333"}
                fontSize={activeCard === "JournalTool" ? "15px" : ["13px"]}
                fontWeight={["500"]}
                w={["70%", "80%"]}
              >
                Access valuable Forex and Crypto AI powered insights to stay
                ahead of trends. Let us empower your trading strategy with
                actionable intelligence.
              </Text>
              <Link to={"/signin"}>
                <Button
                  backgroundColor={"#fff"}
                  color={"#3EA842"}
                  variant="ghost"
                  borderRadius={"6px"}
                  fontSize={["12px", "14px", "16px"]}
                  _hover={{ bg: "#083205", color: "#fff" }}
                  width={"fit-content"}
                >
                  Learn More{" "}
                </Button>
              </Link>
            </Box>
          </Box>{" "}
        </Box>
      </Box>
      <div id="#testimonials" />
      <SectionContainer>
        <VStack width={"full"} gap={["16px", "20px"]} py={["60px", "100px"]}>
          <Text
            color={"#141414"}
            fontSize={["18px", "18px", "28px"]}
            fontWeight={"600"}
            textAlign={"center"}
          >
            What clients say about QuidX
          </Text>
          <Text
            color={"#333333"}
            fontSize={["13px", "13px", "16px"]}
            textAlign={"center"}
            maxW={["full", "full", "75%"]}
          >
            Success stories from our satisfied users, showcasing the positive
            impact QuidX has made on their trading experience.
          </Text>
          <HStack gap={2} flexDir={["column", "column", "row"]}>
            <TestimonialCarousel />
            <MobileTestCarousel />
          </HStack>
        </VStack>
      </SectionContainer>
      <SectionContainer bg={"#1B4B1D"}>
        <VStack gap={"10px"}>
          <Text
            color={"#F8FCF8"}
            fontSize={["18px", "18px", "28px"]}
            fontWeight={"600"}
            textAlign={"center"}
          >
            Stay Informed
          </Text>
          <Text color={"#F8FCF8"} textAlign={"center"}>
            Subscribe to the newsletter to hear about QuidX AI updates and
            events
          </Text>
          <Box
            display={"flex"}
            border={"1px solid #F8FCF8"}
            padding={"2px"}
            borderRadius={"8px"}
            gap="10px"
            width={["100%", "60%"]}
          >
            <Button
              type="submit"
              alignSelf={"center"}
              bg="#DAF1DB"
              fontSize={"16px"}
              color="#1B4B1D"
              fontWeight={"500"}
              onClick={sendWaitlist}
              padding={"30px"}
            >
              Join Waitlist
            </Button>
            <Input
              border={"none"}
              borderLeft={"1px solid #F8FCF8"}
              borderRadius={"none"}
              color={"#F8FCF8"}
              type={"text"}
              name={"email"}
              placeholder={"Email Address"}
              outline={"none"}
              height={"full"}
              required={true}
              py={"20px"}
              alignSelf={"flex-center"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              width={["100%", "100%"]}
            />{" "}
          </Box>
        </VStack>
      </SectionContainer>
      <SectionContainer>
        <Box
          display={"flex"}
          flexDir={["column", "row"]}
          width={"full"}
          gap={[5, 5, 10]}
          alignItems={"center"}
          bg={"#1B4B1D"}
          boxShadow={"0px 1px 4px rgba(255, 255, 255, 0.04)"}
          borderRadius={"24px"}
          p={["0px", "96px 56px"]}
          justifyContent={["center", "space-between"]}
          border={"10px solid #F2F2F2"}
          py={"70px"}
          paddingRight={["0px", "160px"]}
        >
          <Text
            color={"#F8FCF8"}
            fontWeight={"500"}
            fontSize={["14px", "14px", "30px"]}
            textAlign={["center", "left"]}
          >
            Ready to Explore QuidX? Claim <br />
            your free account{" "}
          </Text>
          <Link to="/signup">
            <Button
              bg={"#DAF1DB"}
              color={"black"}
              height={"60px"}
              borderRadius={"6px"}
              fontWeight={"500"}
              paddingX={"70px"}
            >
              Get Started
            </Button>
          </Link>
        </Box>
      </SectionContainer>
    </ThemeLayout>
  );
};

export default Home;
