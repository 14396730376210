// import { EmailIcon, LockIcon } from "@chakra-ui/icons";
// import { Box, HStack, Image, useToast, Text, VStack } from "@chakra-ui/react";
// import AuthButton from "../components/AuthButton";
// import AuthInput from "../components/AuthInput";
// import { Link, useNavigate } from "react-router-dom";

// import { set, useForm } from "react-hook-form";
// import axios from "axios";
// import { createContext, useState } from "react";
// export const UserContext = createContext();

// const Signin = () => {
//   const navigate = useNavigate();
//   const toast = useToast(false);
//   const [loading, setLoading] = useState(false);
//   const [userInfo, setUserInfo] = useState({});
//   const {
//     register,
//     handleSubmit,
//     watch,
//     formState: { errors },
//   } = useForm();

//   const login = async (data) => {
//     setLoading(true);
//     await axios
//       .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, data)
//       .then((response) => {
//         setLoading(false);
//         setUserInfo(response.data.data);
//         toast({ status: "success", title: "Login Successful" });
//         localStorage.setItem("quidsToken", response.data.token);
//         localStorage.setItem("userId", response.data.data.userId);

//         navigate("/dashboard");
//       })
//       .catch((error) => {
//         setLoading(false);
//         toast({ status: "warning", title: error.response.data.message });
//       });
//   };
//   return (
//     <Box
//       width="full"
//       height={"100vh"}
//       padding={"20px"}
//       display={"flex"}
//       justifyContent={"center"}
//       alignItems={"center"}
//     >
//       <HStack
//         width={"full"}
//         height={"95%"}
//         gap={["0px", "0px", "51px"]}
//       >
//         <Box
//           width={["0%", "50%", "50%"]}
//           height={"100%"}
//           borderRadius={"32px"}
//           background="white"
//           display={("none", "flex", "flex")}
//           justifyContent={"center"}
//           alignItems={"center"}
//           bgImage={"url(/assets/images/bg.png)"}
//           backgroundRepeat={"no-repeat"}
//           backgroundSize={"cover"}
//         >
//           <Image src="/assets/images/metaverse.png" />
//         </Box>
//         <Box
//           width={["full", "100%", "50%"]}
//           flex={1}
//           paddingRight={["0px", "0px", "190px"]}
//           display={"flex"}
//           flexDir={"column"}
//           marginLeft={0}
//           alignItems={["center", "center", "start"]}
//         >
//           <form style={{ width: "100%" }} onSubmit={handleSubmit(login)}>
//             <VStack width={"full"} alignItems={"flex-start"} gap={"2"}>
//               {" "}
//               <Text
//                 color={"#141414"}
//                 fontWeight={"700"}
//                 fontSize={["30px", "36px", "48px"]}
//                 textAlign={["center", "left", "left"]}
//                 lineHeight={"normal"}
//                 marginBottom={"48px"}
//               >
//                 {" "}
//                 Sign in to QuidX
//               </Text>
//               <AuthInput
//                 placeholder={"Email Address"}
//                 icon={<EmailIcon />}
//                 type={"email"}
//                 name={"email"}
//                 register={register("email")}
//                 required={true}
//               />
//               <AuthInput
//                 placeholder={"Password"}
//                 type={"password"}
//                 name={"password"}
//                 register={register("password")}
//                 icon={<LockIcon />}
//                 required={true}
//               />
//               <Text marginBottom={"50px"} alignSelf={"flex-start"}>
//                 <Link to={"/forgot-password"}>Forgot Password?</Link>
//               </Text>
//               <Box
//                 width="full"
//                 display={"flex"}
//                 flexDir={"column"}
//                 alignItems={"center"}
//                 gap={"24px"}
//                 marginBottom={"12px"}
//               >
//                 <AuthButton
//                   bg="black"
//                   color="white"
//                   isLoading={loading}
//                   type={"submit"}
//                 >
//                   Sign In
//                 </AuthButton>
//                 <AuthButton bg="#F6FFF5" color="black">
//                   <Image src="/assets/images/google.png" />
//                   Sign up with Google
//                 </AuthButton>
//               </Box>
//               <HStack>
//                 {" "}
//                 <Text display={"flex"} gap={"2px"} alignSelf={"center"}>
//                   Don't have an account?{" "}
//                 </Text>
//                 <Link to={"/signup"}>
//                   <Text fontWeight={700} cursor={"pointer"}>
//                     Sign Up
//                   </Text>
//                 </Link>{" "}
//               </HStack>
//             </VStack>
//           </form>
//         </Box>
//       </HStack>
//     </Box>
//   );
// };

// export default Signin;

import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { EmailIcon, LockIcon } from "@chakra-ui/icons";
import { Box, HStack, Image, useToast, Text, VStack } from "@chakra-ui/react";
import { createContext, useState } from "react";
import { set, useForm } from "react-hook-form";
import axios from "axios";

export const UserContext = createContext();

const Signin = () => {
  const navigate = useNavigate();
  const toast = useToast(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [inputType, setInputType] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const login = async (data) => {
    setLoading(true);
    console.log(data, "Login Data");
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, data)
      .then((response) => {
        setLoading(false);
        setUserInfo(response.data.data);
        toast({ status: "success", title: "Login Successful" });
        localStorage.setItem("quidsToken", response.data.token);
        localStorage.setItem("userId", response.data.data.userId);

        navigate("/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        toast({ status: "warning", title: error.response.data.message });
      });
  };
  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white lg:w-9/12 w-full h-11/12 shadow-lg rounded-lg overflow-hidden flex flex-col lg:flex-row">
          {/* Left Section */}
          <div className="bg-green-900 p-8 flex items-center justify-center">
            <img
              src="/assets/images/metaverse.png"
              alt="VR Illustration"
              className="w-full max-w-sm"
            />
          </div>

          {/* Right Section */}
          <div className="flex-1 p-8">
            <div>
              <h2 className="text-2xl font-bold text-green-900 mb-4">Sign in to QuidX</h2>
              <form className="space-y-4" onSubmit={handleSubmit(login)}>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Email Address</label>
                  <div className='group flex items-center gap-1 p-2 border border-gray-300 rounded-md focus-within:border-[var(--primary-color)]'>
                    <i className="fa fa-envelope text-2xl text-[var(--primary-color)]"></i>
                    <input
                      {...register("email", { required: "Email is required" })}
                      name='email'
                      type="email"
                      required
                      placeholder="Email Address"
                      className="w-full px-4 py-2 border-none focus:outline-none"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Password</label>
                  <div className='group flex items-center gap-1 p-2 border border-gray-300 rounded-md focus-within:border-[var(--primary-color)]'>
                    <i className="fa fa-key text-2xl text-[var(--primary-color)]"></i>
                    <input
                      {...register("password", { required: "Password is required" })}
                      name='password'
                      type={inputType ? "text" : "password"}
                      required
                      placeholder="Password"
                      className="w-full px-4 py-2 border-none focus:outline-none"
                    />
                    <button onClick={() => setInputType(!inputType)} type='button'><i className={inputType ? "fa fa-eye" : "fa fa-eye-slash"}></i></button>
                  </div>
                </div>
                <div className="text-right">
                  <a href="#" className="text-sm text-green-500 hover:underline">Forgot Password?</a>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full py-3 text-[18px] font-[700] bg-[var(--primary-color)] text-white rounded-md hover:bg-green-600"
                >
                  {loading ?
                    <i className="fa fa-spinner text-2xl text-white animate-spin"></i>
                    : "Sign In"
                  }
                </button>
              </form>
              <div className="mt-4">
                <button
                  type="button"
                  className="w-full py-3 text-[18px] font-[700] border border-2 border-[var(--primary-color)] rounded-md flex items-center justify-center gap-2 hover:bg-gray-100"
                >
                  <img src="/assets/images/google.png" alt="Google" className="w-5 h-5" />
                  Sign up with Google
                </button>
              </div>
              <p className="mt-4 text-center text-sm">
                Don’t have an account?{' '}
                <Link to={"/signup"} className="text-green-500 hover:underline">Sign Up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Signin