import {
  Button,
  Container,
  HStack,
  Image,
  Box,
  VStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Text,
  useDisclosure,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React, { useRef } from "react";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";

const Header = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const url = window.location.href;
  const param = url.split("/")[3];
  const toast = useToast();

  return (
    <Box width={"full"} bg={"transparent"}>
      <Container maxWidth={"90%"} display={["none", "none", "block"]}>
        <HStack
          width={"full"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={"10px"}
          borderBottom={"1px solid #E6E5E5"}
        >
          {props.showLogo && (
            <Link to={"/"}>
              <Image src="/assets/images/quidsailogo.png" width={"66px"} />
            </Link>
          )}

          <HStack alignItems={"center"} gap={"40px"}>
            <Link
              to={"/"}
              style={{
                color: "#141414",
                fontWeight: !param ? "600" : "",
                fontSize: "20px",
              }}
            >
              Home
            </Link>
            <Menu>
              <MenuButton border={"none"} bg={"transparent"} fontSize={"20px"}>
                Products <ChevronDownIcon />
              </MenuButton>
              <MenuList>
                <Link
                  to={"/chat"}
                  style={{
                    color: "#141414",
                    fontWeight: !param ? "600" : "",
                  }}
                >
                  <MenuItem>Chat X</MenuItem>
                </Link>
                <Link
                  to={"/calculator"}
                  style={{
                    color: "#141414",
                    fontWeight: !param ? "600" : "",
                  }}
                >
                  <MenuItem>Risk Manager</MenuItem>
                </Link>
                <Link
                  onClick={() =>
                    toast({
                      title: "Coming soon ",
                      description:
                        "The Journal is expected to be available soon. Please check back later.",
                      status: "warning",
                      duration: 5000,
                      position: "top-right",
                      isClosable: true,
                    })
                  }
                  style={{
                    color: "#141414",
                    fontWeight: !param ? "600" : "",
                  }}
                >
                  <MenuItem>Journal X</MenuItem>
                </Link>
              </MenuList>
            </Menu>

            <Link
              to={"/pricing"}
              style={{
                color: "#141414",
                fontWeight: param === "pricing" ? "600" : "",
                fontSize: "20px",
              }}
            >
              Pricing
            </Link>
            <Link
              to={"/faq"}
              style={{
                color: "#141414",
                fontWeight: param === "faq" ? "600" : "",
                fontSize: "20px",
              }}
            >
              FAQ
            </Link>
          </HStack>
          <Link to="/signin">
            <Button
              backgroundColor={"#3EA842"}
              color={"#fff"}
              _hover={{ bg: "#3EA842" }}
            >
              Log In{" "}
            </Button>
          </Link>
        </HStack>
      </Container>

      {/* Mobile View */}
      <Container maxWidth={"full"} display={["block", "block", "none"]}>
        <HStack
          width={"full"}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={"12px"}
          borderBottom={"1px solid #E6E5E5"}
        >
          {props.showLogo && (
            <Image src="/assets/images/quidsailogo.png" width={"66px"} />
          )}

          <IconButton
            ref={btnRef}
            onClick={onOpen}
            bg="transparent"
          >
            <HamburgerIcon />
          </IconButton>
          <Drawer
            isOpen={isOpen}
            placement={"right"}
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent alignItems="center">
              <DrawerCloseButton alignSelf="end" mx={15} my={15} />
              <DrawerHeader my={15}>
                <Text as="p"> {"QuidX AI"} </Text>
              </DrawerHeader>
              <DrawerBody>
                <VStack alignItems={"center"} gap={"40px"}>
                  <Link
                    to={"/"}
                    style={{
                      color: "#141414",
                      fontWeight: !param ? "600" : "",
                      fontSize: "20px",
                    }}
                  >
                    Home
                  </Link>
                  <Menu>
                    <MenuButton
                      border={"none"}
                      bg={"transparent"}
                      fontSize={"20px"}
                    >
                      Products <ChevronDownIcon />
                    </MenuButton>
                    <MenuList>
                      <Link
                        to={"/chat"}
                        style={{
                          color: "#141414",
                          fontWeight: !param ? "600" : "",
                        }}
                      >
                        <MenuItem>ChatX</MenuItem>
                      </Link>
                      <Link
                        to={"/calculator"}
                        style={{
                          color: "#141414",
                          fontWeight: !param ? "600" : "",
                        }}
                      >
                        <MenuItem>Risk Manager</MenuItem>
                      </Link>
                      <Link
                        onClick={() =>
                          toast({
                            title: "Coming soon ",
                            description:
                              "The Journal is expected to be available soon. Please check back later.",
                            status: "warning",
                            duration: 5000,
                            position: "top-right",
                            isClosable: true,
                          })
                        }
                        style={{
                          color: "#141414",
                          fontWeight: !param ? "600" : "",
                        }}
                      >
                        <MenuItem>JournalX</MenuItem>
                      </Link>
                    </MenuList>
                  </Menu>
                  <Link
                    to={"/pricing"}
                    style={{
                      color: "#141414",
                      fontWeight: param === "pricing" ? "600" : "",
                      fontSize: "20px",
                    }}
                  >
                    Pricing
                  </Link>
                  <Link
                    to={"/faq"}
                    style={{
                      color: "#141414",
                      fontWeight: param === "faq" ? "600" : "",
                      fontSize: "20px",
                    }}
                  >
                    FAQ
                  </Link>
                  <Link to="/signin">
                    <Button
                      backgroundColor="#3EA842"
                      color={"#fff"}
                      _hover={{ bg: "#3EA842" }}
                    >
                      Log In
                    </Button>
                    {/* <CTAButton text={"Sign up"} /> */}
                  </Link>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </HStack>
      </Container>
    </Box>
  );
};

export default Header;
